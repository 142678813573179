:root {
    /* COLORS */
    --blue: #6c87b8;
    --blue-dark: #4967a0;
    --blue-light: #96afd7;
    --blue-lighter: #c7d1e4;

    --red: #e85959;
    --red-dark: #be2b2b;
    --red-light: #f87575;

    --green: #65c657;
    --green-dark: #43a034;
    --green-light: #83e973;
    --green-excel: #217346;
    --green-excel-dark: #1b5c38;

    --grey: #999999;
    --grey-dark: #7c7c7c;
    --grey-darker: #282c34;
    --grey-light: #b9b9b9;
    --grey-lighter: #f2f2f2;
    --grey-lighter-hover: #d6d6d6;

    --black: #000000;
    --white: #ffffff;

    --yellow: #ffff33;
    --yellow-dark: #cccc00;
    --yellow-light: #ffff99;
    --yellow-select: #f8ff21;

    --orange: #ff991d;
    --orange-dark: #f28500;

    --turquoise: #86c3f0;
    --turquoise-dark: #6cacda;

    /* RESPONSIVE WIDTHS */
    --wrapperWidth: 1600px;
    --wrapperWidthMin: 800px;

    /* MISC */
    --border-radius: 4px;
  }